.rootContainer{
  display: flex;
  flex-direction: column;
}
.DownloadsTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}

.DownloadHeaderWrapper{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 15px 20px;
}
.Menu{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.MenuItem{
    color: var(--color-document-darkBlue);
    font-size: 16px;
    font-weight: bold;
    padding: 3px 25px;
    border: solid var(--color-document-darkBlue) 2px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}
.MenuClicked{
    color: white;
    background-color: var(--color-document-darkBlue);
    font-size: 16px;
    font-weight: bold;
    padding: 3px 25px;
    border: solid var(--color-document-darkBlue) 2px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;

}

@media (min-width:200px) and (max-width:600px) {
    .DownloadHeaderWrapper,.Menu{
       flex-direction: column;
       gap: 10px !important;
    }
}
@media (min-width:601px) and (max-width:767px) {
    .DownloadHeaderWrapper{
       flex-direction: column;
            gap: 10px !important;
         }
    .Menu{
        display: grid;
        gap: 10px !important;
        grid-template-columns: 1fr 1fr;
     }
}
@media (min-width:768px) and (max-width:1024px) {
    .DownloadHeaderWrapper{
        flex-direction: column;
             gap: 10px !important;
          }
     .Menu{
         display: grid;
         gap: 10px !important;
         grid-template-columns: 1fr 1fr;
      }

}