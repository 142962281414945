.PopUpBox{
    margin-left: 550px;
    width:450px;
}
@media (min-width:200px) and (max-width:400px) {
    .PopUpBox{
        margin-left: 50px;
        width:280px;
    }
}
@media (min-width:400px) and (max-width:500px) {
    .PopUpBox{
        margin-left: 70px;
        width:300px;
    }
}
@media (min-width:500px) and (max-width:600px) {
    .PopUpBox{
        margin-left: 100px;
        width:350px;
    }
}
@media (min-width:600px) and (max-width:800px) {
    .PopUpBox{
        margin-left: 300px;
        width:300px;
    }
}
@media (min-width:800px) and (max-width:850px) {
    .PopUpBox{
        margin-left: 300px;
        width:450px;
    }
}
@media (min-width:850px) and (max-width:950px) {
    .PopUpBox{
        margin-left: 350px;
        width:450px;
    }
}
@media (min-width:950px) and (max-width:1150px) {
    .PopUpBox{
        margin-left: 450px;
        width:450px;
    }
}

.PopUpHeader{
    font-weight: bold;
}
.PopUpTitle{
    display: flex;
    justify-content: left;
    font-size: 22px;
}
.PopUpSubmit{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 30px 0px;
}
.PopUpSubmitButton{
    background-color: var(--color-usrPopupSubmit-blue);
    color: white;
    border: none;
    font-weight: bold;
    padding: 5px 30px;
    border-radius: 5px;
}
.addFolderInputWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.addFolderInput{
    border: solid var(--color-docAddfolderInputBorder) 2px;
    width: 80%;
    padding: 10px;
    border-radius: 10px;
}