.rootContainer{
     /* background-color: red; */
    /* padding: 20px; */
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
@media (min-width:200px) and (max-width:600px) {
    .DocumentsHeader{
        display: flex;
     flex-direction: column;
     gap: 15px;
    }
    .DocumentsFolders{
        grid-template-columns: 1fr !important;
        padding: 10px !important;
    }
}
@media (min-width:601px) and (max-width:767px) {
    .DocumentsFolders{
        grid-template-columns: 1fr !important;
    }
}
@media (min-width:768px) and (max-width:1000px) {
    .DocumentsFolders{
        grid-template-columns: 1fr 1fr !important;
    }
}
@media (min-width:1001px) and (max-width:1200px) {
    .DocumentsFolders{
        grid-template-columns: 1fr 1fr 1fr!important;
    }
}
@media (min-width:1201px) and (max-width:1400px) {
    .DocumentsFolders{
        grid-template-columns: 1fr 1fr 1fr 1fr!important;
    }
}

.DocumentsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center ;
    width: 100%;
    padding:13px 40px ;
    background-color: var(--color-usrMgt-titleblue) ;
}
.DocumentsTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    color: white;
    /* color: var(--color-documentsTitle-blue); */
    font-size: 20px;
    font-weight: bold;
}
.CreateFolderButton{
    /* color: var(--color-addButton-orange);
    border: solid var(--color-addButton-orange) 1px;
    background-color: var(--color-documentButtonFill-orange);
    border-radius: 5px;
   padding: 5px 20px; */
   font-size: 15px;
   background-color: var(--color-addButton-orange);
   border: #ffc000 solid 3px;
   padding: 5px 20px;
   border-radius: 5px;
   font-weight: bold;
   color: white;
}
.CreateFolderButtonWrapper{
   display: flex;
}
.DocumentsFolders{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 30px;
    /* gap: 30px; */
}