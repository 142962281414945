.rootContainer{
    display: flex;
    flex-direction: column;
    gap: 25px;
}


.AddConsultantTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}


/* Mobile Resolution */
@media (min-width:200px) and (max-width:900px) {
    .AddConsultantTitle{
        font-size: 24px;
    }
    .AddConsultantBody{
        grid-template-columns: 1fr !important   ;
    }
    .AddConsultantItem{
        flex-direction: column !important;
    }
    .AddConsultantItem label{
        justify-content: flex-start !important;
    }
  
    .AddConsultantCkLstUpldDocsWrapper{
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
    }
    
    .AddConsultantCkLstUpldBtn label{
        padding: 5px 25px 5px 15px !important;
    }

    .AddConsultantCheckListTitleWrapper{
        width: 100% !important;
        padding: 5px !important;
    }
    .ExpertiseSectionList{
        grid-template-columns: auto auto !important;
    }  
    .AddConsultantButtonRowWrapper{
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 10px !important;
        width: 100% !important;
    }
    .AddConsultantRemarks{
        width: 120px !important;
    }
    .AddConsultantCkLstUpldDocsWrapper{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;
    }   
    .AddConsultantCheckList{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .ConsultantCommentsWrapper{
        padding-right: 50px !important;
    }
    .ConsultantNotes{
        width: 100% !important;
    }
}

@media (min-width:601px) and (max-width:1050px){
    .AddConsultantTitle{
        font-size: 26px;
    }
    .AddConsultantBody{
        gap: 15px !important;
        flex-direction: column !important;
    }
    .AddConsultantItem{
        flex-direction: column !important;
    }
    .AddConsultantItem label{
        justify-content: flex-start !important;
    }
    .AddConsultantCkLstUpldDocsWrapper{
        width: 100%;
        grid-template-columns: 1fr 1fr !important;
    }
    .AddConsultantCkLstUpldDocs{
        width: 100% !important;
    }
}
.AddConsultantBody{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 2px 20px;
}

.AddConsultantItem{
    display: flex;
    flex-direction: row;
    gap:20px;
}


.AddConsultantItem label{
    flex:1;
    display: flex;
    justify-content: flex-end;
    color:var(--color-ctrlTextColor-grey);
    font-weight: bold;
}

.ConsultantInputWrapper{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Seperator
{
    border-top: solid var(--color-oppBidBorder-grey) 2px;
    width: 100%;
    margin: 30px 0px;
}

.AddConsultantCheckListWrapper{
    font-size: 15px;
    color: var( --color-ctrlTextColor-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    gap: 30px;
    font-size: 14px;
}
.AddConsultantCheckList{
    border: solid 2px var(--color-addButton-orange);
    background-color: var(--color-oppBidBkgd-pale);
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    width: 90%;
    padding: 10px 0px;
}

.AddConsultantCheckListTitleWrapper{
    padding: 20px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AddConsultantCheckListTitle{
    font-weight: normal;
    display: flex;
    font-size: 14px;
    color: black;
}

.AddConsultantCheckListUploaded{
    width: 70%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.AddConsultantCkLstUpldDocs a{
    max-width: 200px;
    white-space: nowrap; 
    text-overflow: ellipsis !important;
    overflow: hidden;
} 
.AddConsultantButtonRowWrapper{
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 70%;
}

.AddConsultantCkLstUpldBtn input{
    display: none;
}
.AddConsultantCkLstUpldBtn label{
    cursor: pointer;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    background-color: var(--color-addButton-orange);
    color: white;
    font-weight: bold;
    padding: 4px 50px 4px 30px;
    border-radius: 10px;
}

.AddConsultantCkLstUpldDocsWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.AddConsultantCkLstUpldDocs{
    border: solid var(--color-border-blue) 1px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    
    border-radius: 7px;
    background-color: white;
}
.ExpertiseListWrapper{
    display: flex;
    justify-content: center;
}
.ExpertiseSection{
    background-color: var(--color-cntrtFormInvoiceBG);
    border-radius: 10px;
    border: solid var(--color-submitButton-blue) 1px;
    width: 90%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ExpertiseSectionTitle{
    color: var(--color-submitButton-blue);
}
.ExpertiseSectionList{
    display: grid;
    width: 100%;
    padding: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
}
.ExpertiseSectionListItem{
    display: flex;
}

.ConsultantCommentsWrapper{
    display: flex;
    width: 100%;
    padding-right: 150px;
}
.CommentsLabel{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ConsultantNotes{
    width: 80%;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    border-radius: 15px;
    height: 120px;
    color: var( --color-ctrlTextColor-grey);
}
.SubmitButtonWrapper{
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}