.SignInWrapper{
    display: flex;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
}

.SignInImageWrapper{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:200px) and (max-width:600px){

    .SignInWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
     .SignInImageWrapper{
        display: none;
     }

     .SignInFormWrapper{
         margin-top: -250px;
        height: 100vh;
        width: 100%;
     }

     .SignInFormTitle{
        padding: 10px !important;
        font-size: 24px !important;
     }
}
@media (min-width:600px) and (max-width:992px) {
    .SignInWrapper{
        padding: 30px;
    }
     
    .SignInFormTitle{
        
        font-size: 24px !important;
         padding: 10px !important;
    }
}   

.SignInImage{
    width: 100%;
    height: 100%;    
    object-fit: contain;
}

.SignInFormWrapper{
    /* width: 30%;
    height: 80%; */
    display: flex;
    justify-content:center;
    align-items: center;
}

.SignInForm{
    display: flex;
    color: var(--color-inputField-grey );
    flex-direction: column;
    gap:10px;
}

.SignInFormTitle{
    color: var(--color-signInTitle-grey);
    font-size: 30px;
     padding: 10px 15px;
}

.SignInFormUname{
    display: flex;
    flex-direction: row ;
    align-items: center;
    border: solid var(--color-inputBorder-blue) 2px;
    border-radius: 23px;
    padding:0px 20px;

}
.SignInFormPass{
    display: flex;
    flex-direction: row ;
   align-items: center;
    border: solid var(--color-inputBorder-blue) 2px;
    border-radius: 23px;
    padding:0px 20px;
  
}
.SignInFormInput{
    padding: 10px 40px 10px 5px;
    border: none;
    width: 200px;
}

.SignInFormInput:focus{
    outline: none;
}

.SignInFormRememberMe{
    display: flex;
    width: 100%;
    padding: 0px 10px;
    align-items: center;
    gap:10px;
}

.SignInFormRmbrMeTxt{
    margin-top: 6px;
}

.SignInFormRmbrMeChkBx{
    width: 16px;
    height: 16px;
}
.SignInFormSignWrapper{
    display: flex;
    width: 100%;
}

.SignInFormSignInButton{
    background-color: var(--color-submitButton-blue);
    border: solid 2px var(--color-inputBorder-blue);
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 23px;
    width: 100%;
    padding: 7px 0px;
}

.SignInFormSignInSuccessButton{
    background-color: var( --color-submitSuccess-green);
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 23px;
    width: 100%;
    padding: 7px 0px;
}
.ErrorMessage{
    color: red;
    font-size: 12px;
    margin-left: 25px;

}
.ErrorIcon{
    color: red;
    font-size:16px !important;
    margin-right: 5px;
}
.SignInFormSignInNotMatchButton{
    background-color: red;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 23px;
    width: 100%;
    padding: 7px 0px;
}


.SignInFormForgPass{
    display: flex;
    width: 100%;
    justify-content: flex-end;
   
}

.SignInFormForgPassLink{
     cursor: pointer;
    color: var(--color-inputField-grey );
}


.SignInFormForgPassLink:hover{
    text-decoration: underline;
}



.Container{
    display: flex;
    flex-direction: column;
    gap:30px;
}

.ErrorHeading{
    background-color: bisque;
    color: red;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

}

.visible{
    cursor: pointer;
}