.sideBarMenu{
display: flex;
padding: 5px 10px;
width: 100%;
}
.sideBarClose{
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 10px;
}

.OpenCloseIcon{
    cursor: pointer;
}

@media (min-width:601px) {
    .sideBarMenu , .sideBarClose{
        display: none;   
    }
}