.ChangePassWordRootContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;


    .MuiLoadingButton-loadingIndicator {
        color: white !important;

    }

}