.FloatingButton{
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 20px;
}

.loaderContainer{
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}