.ChangePassWrapper{
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 30px;
}
@media (min-width:200px) and (max-width:600px){

     .ChangePassWrapper{
         margin-top: -250px;
        height: 100vh;
        width: 100%;
     }

     .ChangePassFormTitle{
        padding: 10px !important;
        font-size: 24px !important;
     }
}
@media (min-width:600px) and (max-width:992px) {
    .ChangePassWrapper{
        padding: 30px;
    }
     
    .ChangePassFormTitle{
        
        font-size: 24px !important;
         padding-left: 10px !important;
    }
}   

.ChangePassForm{
    display: flex;
    color: var(--color-inputField-grey );
    flex-direction: column;
    gap:20px;
}

.ChangePassFormTitle{
    color: var(--color-signInTitle-grey);
    font-size: 30px;
}

.ChangePassPassword{
    display: flex;
    flex-direction: row ;
    align-items: center;
    border: solid var(--color-inputBorder-blue) 2px;
    border-radius: 23px;
    padding:0px 20px;
}
.ChangePassRePass{
    display: flex;
    flex-direction: row ;
   align-items: center;
    border: solid var(--color-inputBorder-blue) 2px;
    border-radius: 23px;
    padding:0px 20px;
  
}
.ChangePassFormInput{
    padding: 10px 40px 10px 5px;
    border: none;
    width: 200px;
    outline: none;
}



.ChangePassSignInButtonWrapper{
    display: flex;
    width: 100%;
}

.ChangePassFormSignInButton{
    background-color: var(--color-submitButton-blue);
    border: solid 2px var(--color-inputBorder-blue);
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 23px;
    width: 100%;
    padding: 7px 0px;
}


.Error{
    font-size: 12px;
    color:red;
    margin-left: 30px;
    font-weight: bold;
}


.Container{
    display: flex;
    flex-direction: column;
    gap:30px;
}

.ErrorHeading{
    background-color: bisque;
    color: red;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

}