.Container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.usrMngTitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}

/* Mobile Resolution */
@media (min-width:200px) and (max-width:600px) {
    .Container{
        padding: 10px;
    }
    .usrMngTitle {
        width: 100%;
    }

    .Form {
        display: flex;
        flex-direction: column !important;
        gap: 2px !important;
    }

    .userAddMenuInputs {
        flex-direction: column !important;
        margin-top: 10px !important;
    }

    .userAddMenu {
        height: auto;
    }

    .TextField {
        width: 200px !important;
    }

    .tableContainer {
        position: absolute !important;
        width: 100% !important;
    }

    .usrMgtTable {
        font-size: 11px !important;
        overflow-x: scroll !important;
        height: auto !important;
    }

    .levelButton1,
    .levelButton2,
    .levelButton3 {
        padding: 7px !important;
    }

    .PopUpBox {
        width: 90% !important;
        margin-left: 5% !important;
    }

    .PopUpForm {
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important;
    }

    .PopUpInput {
        min-width: 200px;
        width: auto;
    }
}

/* Tablet Resolution */
@media (min-width:601px) and (max-width:992px) {
    .usrMngTitle {
        width: 100%;
    }

    .userAddMenuInputs {
        flex-direction: column !important;
        gap: 10px !important;
        justify-content: center;
        align-items: center;
    }

    .Form {
        display: grid !important;
        grid-template-columns: 50% 50% !important;
        gap: 10px !important;
        width: 100% !important;
        justify-content: center !important;
        align-items: center !important;
    }



    .TextField {
        width: auto !important;
        min-width: 30px !important;
        max-width: 150px !important;
    }

    .tableContainer {

        position: absolute !important;
        width: 70% !important;
        overflow-x: scroll !important;
        height: auto;
    }

    .levelButton1,
    .levelButton2,
    .levelButton3 {
        padding: 7px !important;
    }

    .AddNewUserButton {

        padding: 5px 10px !important;
    }

    .PopUpBox {
        width: 60% !important;
        margin-left: 32% !important;
    }

    .PopUpForm {
        display: flex !important;
        flex-direction: column !important;
        gap: 10px !important;
    }

    .PopUpInput {
        min-width: 200px;
        width: auto;
    }
}

@media screen and (max-width:992px) {
    .userAddMenuWrapper{

        width: 100% !important;
        padding: 10px !important;
    }
}

.AddContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userAddMenuWrapper {
    padding: 30px 0px;
    border-radius: 20px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-border-blue);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.userAddMenu {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border: solid var(--color-border-blue) 1px;
    border-radius: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userAddMenuInputs {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.Form {
    gap: 50px;
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.TextField {
    width: 150px;
}

.AddNewUserButton {
    background-color: var(--color-addButton-orange);
    border: #ffc000 solid 4px;
    padding: 6px 30px 6px 30px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
}

.tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    width: 100%;
}

.usrMgtTable {
    font-size: 14px;
    color: var(--color-tableText-blue);
    margin-top: 30px;
    align-items: center;
    text-align: center;

    width: 100%;
}

.tableHead {
    background: var(--color-tablehead-blue);
    color: white;
}

.tableHead th,
td {
    padding: 15px;

}

.tableRow {
    background-color: var(--color-tableAltTdBG-blue);
}


.levelButton1 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level1Button-green);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.levelButton2 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level2Button-blue);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.levelButton3 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level3Button-orange);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: black solid 1px;
    border-radius: 100%;
    cursor: pointer;
}

.PopUpTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 22px;
}


.PopUpBox {
    width: 70%;
    margin-left: 24%;
}



.StatusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PopUpHeader {
    background-color: var(--color-usrPopupHeader-blue);
    color: white;
    font-weight: bold;
}

.PopUpForm {
    gap: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.PopUpSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 30px 0px;
}

.PopUpSubmitButton {
    background-color: var(--color-usrPopupSubmit-blue);
    color: white;
    border: solid 4px #a9ddb8;
    font-weight: bold;
    padding: 3px 30px 3px 30px;
    border-radius: 10px;
}