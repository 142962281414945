.tableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow:auto ;

}

.usrMgtTable {
    font-size: 14px;
    color: var(--color-tableText-blue);
    align-items: center;
    text-align: center;
    width: 100%;
}

.tableHead {
    background: var(--color-tablehead-blue);
    color: white;
}

.tableHead th,
td {
    padding: 15px;

}

.tableRow {
    background-color: var(--color-tableAltTdBG-blue);
}

.levelButton1 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level1Button-green);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.levelButton2 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level2Button-blue);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.levelButton3 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level3Button-orange);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: black solid 1px;
    border-radius: 100%;
    cursor: pointer;
}


@media (min-width:200px) and (max-width:600px) {
    .tableContainer {
        position: absolute !important;
        width: 100% !important;
        overflow-x: auto;
    }

}

tbody{
    width: 100%;
}

.StatusContainer{
    width: 100%;
    display:flex;
    align-items:center;
    justify-content: center;
}