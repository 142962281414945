.drawerTitle {
    font-size: 11px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    font-weight: bold;
    padding: 2px 10px 0px 15px;
    color: grey;
}

.DrawerOptions {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.OptionWrapper{
    display: flex;
    flex-direction: column;
    
    
}

.DrawerOptions:hover {
    background-color: rgb(233, 238, 244);
    border-radius: 8px;
}


.link {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    text-decoration: none;
    color: black;
    border-radius: 5px;
    padding: 0px 10px;
    gap: 10px   ;
   
}

.activelink{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  background-color: rgba(233,238,244);
    text-decoration: none;
    border-radius: 8px;
    padding: 0px 10px;
    gap: 10px   ;
}

.link:active{
    color: blue;
    background-color: rgb(233,238,244);
}

.DrawerListOptionsIcon {
    color: grey;
     min-width: 30px;  
     padding: 10px;
}

.DrawerListOptionsIconActive {
    color: grey;
    min-width: 30px;  
    padding: 10px;
}

.DrawerListOptionsTitle {
    font-weight: bold;
    width: 100%;
    padding: 5px;
    font-family: sans-serif;
    font-size: 13px; 
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.DrawerListOptionsTitleActive {
    width: 100%;
    padding: 5px;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.Version{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items: center;
  gap:10px;
}

.Logout{
    width: 50px;
    height:50px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
}

.Logout:hover{
    background-color: var(--color-docSubmitHeader-blue);
}

.Conta{
    padding: 15px 10px;
    gap:5px;
    cursor: pointer !important;
}

.LogoutText{
    font-size: 12px;
    font-weight: bold;
    cursor: pointer !important;
}

.arrowIcon{
    width: 20px;
}