.unAuthorizedWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: var(--color-tableText-blue);
}
.unAuthorizedImage{
    width: 200px;
    padding: 20px;
    margin-left: 30px;
}
@media screen and (max-width:600px) {
    .unAuthorizedWarning{
        font-size: 18px;
    }
    .unAuthorizedImage{
        width: 150px;
        margin-left: 15px;
    }
}
@media (min-width:600px) and (max-width:992px){
    .unAuthorizedWarning{
        font-size: 22px;
    }
    .unAuthorizedImage{
        width: 170px;
        margin-left: 20px;
    }
}