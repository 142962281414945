.invoiceContainer{
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: solid  var(--color-ctrlFieldSubmitBorder-blue) 2px;
    border-radius: 20px;
}
.ChildTableFormWrapper{
    
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 20px 35px;
    gap: 20px;
}
.ChildTableForm{
    gap: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.ChildTableTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: var(--color-usrMgt-titleblue);
    font-size: 25px;
}
.ChildTable{
    width: 100%;
    color: var(--color-tableText-blue);
    text-align: center;
    
}
.ProjectsTableHead{
    background-color: var(--color-border-blue);
    color: white;    
}
.ProjectsTableTH, .ProjectsTableTD{
    font-size: 13px;
    padding: 10px;
}
.AddChildTableWrapper{
    overflow-x: scroll ;
    border-radius: 15px;
}

.TableBodyAltBg{
    background-color: var(--color-tableAltTdBG-blue);
}
.UpdateButtonWrapper{
    display :flex;
    gap: 30px;
}


.AddInvoiceButton{
    border: solid var(--color-ctrlFieldSubmitBorder-blue) 2px;
background-color: var( --color-oppBidSubmitBtn-blue);
font-weight: bold;
color: white;
padding: 5px 25px 5px 25px;
border-radius: 10px;
}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: black solid 1px;
    border-radius: 100%;
    cursor: pointer;
}

@media (min-width:200px) and (max-width:900px){
    .ChildTableForm{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .AddChildTableWrapper{
        max-width: 85vw;
    }

}


@media (min-width:901px) and (max-width:1200px){
    .ChildTableForm{
        grid-template-columns: 1fr 1fr;
    }
}
