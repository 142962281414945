.rootContainer{
    display: flex;
    flex-direction: column;
    gap: 10px ;
}

.ProjectMngDetailsTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding:13px ;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue) ;
}



   

/* Mobile Resolution */
@media (min-width:200px) and (max-width:950px) {
    .ProjectMngDetailsTitle{
        padding-bottom: 15px;
    }
    .filterOptionsLeftWrapper{
        flex-direction: column;
        gap: 15px !important;
    }
    .fltrOpnsColumn{
        width: 140px !important;
    }
    .filterWrapper{
        flex-direction: column;
        gap: 15px !important;
    }
    .ButtonWrapper{
        display: flex;
        justify-content: center;
    }
    .fltrOpnsColumnRightWrapper{
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 0px !important;
    }
    .sortTableButton{
        padding: 0px !important;
        bottom: 0px !important; 
        width: 140px !important;
        font-size: 15px !important;
    }
    .ProjectsTableWrapper{
        max-width: 95vw;
    }
}
@media (min-width:951px) and (max-width:1140px) {
    .ProjectMngDetailsTitle{
        padding-bottom: 15px;
    }
    .filterWrapper{
        flex-direction: column;
        gap: 15px;
    }
    .fltrOpnsColumnRightWrapper{
        display: flex;
        justify-content: center;
    }
   .sortTableButton{
        padding: 0px !important;
        width: 140px !important;
        font-size: 15px !important;
    }
}

.filterWrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.filterOptionsLeftWrapper{
    display: flex;
    gap: 30px;
    font-size: 14px;
}
.filterOptionsWrapper{
    display: flex;
    color: var(--color-ctrlTextColor-grey);
    gap: 30px;
}

.fltrOpnsColumn{
    width: 120px;
    max-height: 35px;
    padding: 3px 15px 3px 10px;
    border: var(--color-oppBidBorder-grey) solid 2px;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    gap: 10px;
}

.fltrOpnsColumnRightWrapper{
    display: flex;
    align-items: center;
}
.tablePagination{
    min-width: 120px;
}
.ProjectsTableWrapper{
    overflow: auto;
    width: 100%;
    height: 470px !important;
}

.ProjectsTable{
    width: 100%;
    color: var(--color-tableText-blue);
    text-align: center;
}
.TableBodyAltBg{
    background-color: var(--color-tableAltTdBG-blue);
}

.ProjectsTableHead{
    background-color: var(--color-border-blue);
    color: white;
    position: sticky;
    top:0px;
}

.ProjectsTableTH{
    font-size: 14px;
    padding: 15px;
    min-width: 120px;
    white-space: nowrap;

}
.ProjectsTableTD {
    font-size: 14px;
    padding: 15px;
    min-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
    max-width: 180px;
}
.ChildTable{
    width: 100%;
    color: var(--color-tableText-blue);
    text-align: center;
}
.ChildTableWrapper{
    overflow-x: scroll ;
}
.AddProjectButton{
    background-color: var(--color-addButton-orange);
    border: #ffc000 solid 3px;
    padding: 5px;
    width: 120px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
}

.InvoiceButton{
    background-color: var(--color-addButton-orange);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    border: none;
    color: white;
}



.sortTableButton{
    border-radius: 10px !important;
    font-size: 14px !important;
    padding: 5px;
}

.sortTableButtonList{
    font-size: 14px;
}

.PopUpBox{
    margin-left: 300px;
    width: 75%;
}

.PopUpHeader{
    background-color: var( --color-usrPopupHeader-blue);
    color: white;
    font-weight: bold;
}
.PopUpTitle{
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 22px;
}
.PopUpSubmit{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 30px 0px;
}
.PopUpSubmitButton{
    background-color: var(--color-usrPopupSubmit-blue);
    color: white;
    border: solid 4px #a9ddb8;
    font-weight: bold;
    padding: 3px 30px 3px 30px;
    border-radius: 10px;
}

.ColumnSelect{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 300px;
    gap: 5px;
    overflow-y: scroll;
}

@media (min-width:250px) and (max-width:400px) {
    .PopUpBox {
       margin-left: 50px !important;
    }

    .ColumnSelect{
        grid-template-columns: 1fr !important;
    }

}

@media (min-width:400px) and (max-width:600px) {
    .PopUpBox {
       margin-left: 90px !important;
    }

    .ColumnSelect{
        grid-template-columns: 1fr !important;
    }

}

@media (min-width:600px) and (max-width:992px) {
    .PopUpBox {
        margin-left: 240px !important;
        width: 60% !important;
    }

    .ColumnSelect{
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    
    .PopUpBox {
        margin-left: 250px !important;
        width: 75%;
    }
}