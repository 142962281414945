.rootContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ConsultantsIndexTitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}

@media (min-width:200px) and (max-width:600px) {
    .FilterConsultantsWrapper {
        flex-direction: column !important;
        gap: 15px !important;
    }

    .ConsultantsFilterBodyItems {
        flex-direction: column;
        gap: 10px !important;
    }

    .FilterButton {
        display: flex;
        width: 100%;

        justify-content: flex-end !important;
    }

    .ConsultantsFilterBody {
        flex-direction: column-reverse !important;
        justify-content: center !important;
    }

    .tableContainer {
        width: 100vw;
    }

    .AddConsultantButton {
        font-size: 14px;
    }
}

@media (min-width:601px) and (max-width:830px) {
    .ConsultantsFilterBodyItems {
        grid-template-columns: auto !important;
    }

    .FilterItems {
        width: 80% !important;
        min-width: 160px !important;
    }

}

@media (min-width:601px) and (max-width:1090px) {
    .AddConsultantButton {
        font-size: 14px;
    }
    .FilterConsultantsWrapper {
        flex-direction: column !important;
        gap: 15px !important;
    }
    .ConsultantsFilterBodyItems {
        display: grid !important;
        grid-template-columns: auto auto;
        gap: 10px !important;
    }

    .FilterButton {
        display: flex;
        width: 100%;

        justify-content: flex-end !important;
    }

    .ConsultantsFilterBody {
        flex-direction: column-reverse !important;
        justify-content: center !important;
    }

    .FilterItems {
        width: 80% !important;
        min-width: 160px !important;
    }
}

.FilterConsultantsWrapper {
    display: flex;
    flex-direction: row;
    padding: 0px 30px;
    gap: 30px;
    align-items: center;
}

.ConsultantsFilterBodyItems {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.ConsultantsFilterBody {
    padding: 10px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border: solid var(--color-border-blue) 1px;
    border-radius: 10px;
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FilterButton {
    font-size: 30px !important;
    color: var(--color-addButton-orange);
}

.FilterItems {
    min-width: 160px;
    border: solid var(--color-consultantsIndexfilterBorder-blue) 1px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-ctrlTextColor-grey);
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
}
.AddExportConsultantWrapper{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 10px;
}
.AddConsultantButton {
    background-color: var(--color-addButton-orange);
    padding: 7px;
    width: 140px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    color: white;
}
.fltrOpnsColumn{ 
     max-height: 35px;
    width: 140px;
    border: var(--color-oppBidBorder-grey) solid 2px;
    border-radius: 5px;
    display: flex;
    color: var(--color-ctrlTextColor-grey);
    cursor: pointer;
    padding: 2px 0px 3px 25px ;
    gap: 5px;
}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: black solid 1px;
    border-radius: 100%;
    cursor: pointer;
}

.tableContainer {
    overflow-x: scroll;
}

.ConsultantsTable {
    width: 100%;
    color: var(--color-tableText-blue);
    text-align: center;
}

.tableHead {
    background: var(--color-tablehead-blue);
    color: white;
}

.ConsultantTableHead {
    font-size: 14px;
    padding: 15px;
    min-width: 120px;
    white-space: nowrap;
}

.ConsultantTableTD {
    font-size: 14px;
    padding: 15px;
    min-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
    max-width: 180px;
}

.tableRow {
    background-color: var(--color-tableAltTdBG-blue);
}


.levelButton1 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level1Button-green);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.levelButton2 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level2Button-blue);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}

.levelButton3 {
    padding: 5px 20px 5px 20px;
    background-color: var(--color-level3Button-orange);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
}


.StatusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}