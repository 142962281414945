.rootContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.OppBidTitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}

.OppBidBody{
   
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 0px 20px;
}

.OppBidItem{
    display: flex;
    flex-direction: row;
    gap:20px;

}

.OppBidItem label{
   flex:1;
   display: flex;
   justify-content: flex-end;
   color:var(--color-ctrlTextColor-grey);
   font-weight: bold;
}

.TenderInputWrapper{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}



.InputField{
   width:100%;
}

.emptyMessage
{
    color: red !important;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
}

.Seperator
{
    border-top: solid var(--color-oppBidBorder-grey) 2px;
    width: 100%;
    margin: 10px 0px;
}

.OppBidCheckListWrapper{
    font-size: 15px;
    color: var( --color-ctrlTextColor-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 14px;
}





.OppBidCheckList{
    border: solid 2px var(--color-addButton-orange);
    background-color: var(--color-oppBidBkgd-pale);
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    width: 90%;
    min-height: 8%;
    padding: 20px 0px;
}

.OppBidCheckListTitleWrapper{
    padding: 20px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.OppBidCheckListTitle{
    font-weight: normal;
    font-size: 14px;
    color: black;
    width: 150px;
}

.OppBidCheckListUploaded{
    width: 70%;
    display: flex;
    flex-direction: column;
}
.OppBidCkLstUpldBtn{
    height: 50%;
    margin-bottom: 15px;
}
.OppBidCkLstUpldBtn input{
    display: none;
}
.OppBidCkLstUpldBtn label{
    cursor: pointer;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    background-color: var(--color-addButton-orange);
    color: white;
    font-weight: bold;
    padding: 4px 50px 4px 30px;
    border-radius: 10px;
}

.OppBidCkLstUpldDocsWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.OppBidCkLstUpldDocs{
    border: solid var(--color-border-blue) 1px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    min-width: 150px;
    border-radius: 7px;
    background-color: white;
}

.OppBidCkLstUpldDocs a{
    max-width: 200px;
white-space: nowrap; 
text-overflow: ellipsis !important;
overflow: hidden;
}
.OppBidNotesWrapper{
    display: flex;
    width: 90%;
    padding: 10px 0px;
}
.OppBidNotesLabel{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.OppBidNotes{
    width: 80%;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    border-radius: 15px;
    height: 150px;
    color: var( --color-ctrlTextColor-grey);
}
.submitButtonWrapper{
    display: flex;
    justify-content: center;
    padding: 30px;
}

@media screen and (max-width:900px){
    .OppBidBody{
        grid-template-columns: 1fr;
    }

    .OppBidItem{
        flex-direction: column;
    }

    .OppBidItem label{
        justify-content: flex-start;
    }
}


@media screen and (max-width:1200px){
    .OppBidItem{
        flex-direction: column;
    }

    .OppBidItem label{
        justify-content: flex-start;
    }
}



.OppBidCkLstUpldBtnDisabled label{
    cursor: pointer;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    background-color: var(--color-inputField-grey);
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 4px 50px 4px 30px;
    border-radius: 10px;
}