.rootContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.AddProjectTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}

.AddProjectBody{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 2px 20px;
}

.AddProjectItem{
    display: flex;
    flex-direction: row;
    gap:20px;
}
.AddProjectItem label{
    flex:1;
    display: flex;
    justify-content: flex-end;
    color:var(--color-ctrlTextColor-grey);
    font-weight: bold;
}

.ProjectInputWrapper{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.InputField{
    width:100%;
}
.ChoseTenderInput{
    max-width: 250px;
}

.TableBodyAltBg{
    background-color: var(--color-tableAltTdBG-blue);
}

.emptyMessage
{
    color: red !important;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
}

.Seperator
{
    border-top: solid var(--color-oppBidBorder-grey) 2px;
    width: 100%;
}

.AddProjectCheckListWrapper{
    font-size: 15px;
    color: var( --color-ctrlTextColor-grey);
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-size: 14px;
}
.AddProjectCheckList{
    border: solid 2px var(--color-addButton-orange);
    background-color: var(--color-oppBidBkgd-pale);
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    width: 90%;
    padding: 10px 0px;
}

.AddProjectCheckListTitleWrapper{
    padding: 20px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AddProjectCheckListTitle{
    font-weight: normal;
    display: flex;
    font-size: 14px;
    color: black;
}

.AddProjectCheckListUploaded{
    width: 70%;
    display: flex;
    flex-direction: column;
}
.AddProjectCkLstUpldBtn{
   padding: 10px 20px;
}
.AddProjectCkLstUpldBtn input{
    display: none;
}
.AddProjectCkLstUpldBtn label{
    cursor: pointer;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    background-color: var(--color-addButton-orange);
    color: white;
    font-weight: bold;
    padding: 4px 50px 4px 30px;
    border-radius: 10px;
}

.AddProjectCkLstUpldDocsWrapper{
    display: grid;
    padding: 0px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.AddProjectCkLstUpldDocs{
    border: solid var(--color-border-blue) 1px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 7px;
    background-color: white;
}
.AddProjectCkLstUpldDocs a{
    max-width: 200px;
white-space: nowrap; 
text-overflow: ellipsis !important;
overflow: hidden;
}


.AddProjectWrapper{
    display: flex;
    width: 90%;
    margin: auto;
}
.AddProjectLabel{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.AddProjectNotes{
    width: 80%;
    border: solid var(--color-oppBidUploadBorder-blue) 2px;
    border-radius: 15px;
    height: 150px;
    color: var( --color-ctrlTextColor-grey);
}
.submitButtonWrapper{
    display: flex;
    justify-content: center;
    padding: 20px 0px 30px 0px;
}

.InvoiceWrapper{
    padding: 5px 30px;
}
.AddChildTableWrapper{
    overflow-x: scroll;
    width: 100%;
}
@media (min-width:200px) and (max-width:900px){
    .AddProjectBody{
        grid-template-columns: 1fr;
    }

    .AddProjectItem{
        flex-direction: column;
    }

    .AddProjectItem label{
        justify-content: flex-start;
    }
    .AddProjectCheckList{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ChoseTenderInput{
        max-width: 300px;
    }
    .AddProjectCheckListTitleWrapper{
        padding: 5px;
        width: 100%;
    }
    .AddProjectCheckListUploaded{
      width: 100%;
      gap: 10px;
      display: flex;
      flex-direction: column;
      justify-content:center ;
      align-items: center;
    }
    .AddProjectCkLstUpldBtn{
        padding: 0px 20px 10px 20px ;
        width: 100%;
        margin-bottom: 0px;
        display: flex;
      justify-content:center ;
      align-items: center;
    }
    .AddProjectCkLstUpldDocsWrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center ;
        align-items: center;
    }
}


@media (min-width:901px) and (max-width:1200px){
    .AddProjectItem{
        flex-direction: column;
    }

    .AddProjectItem label{
        justify-content: flex-start;
    }
    .AddProjectCheckListUploaded{
        width: 100% !important;
    }
    .AddProjectCkLstUpldDocs{
        width: 100% !important;
    }
    .ChoseTenderInput{
        max-width: 300px;
    }
    
    .AddProjectCkLstUpldDocsWrapper{
        width: 100%;
        grid-template-columns: 1fr 1fr !important;
    }
}
@media (min-width:300px) and (max-width:500px){
    .ChoseTenderInput{
        max-width: 350px;
    }
}
@media (min-width:501px) and (max-width:600px){
    .ChoseTenderInput{
        max-width: 500px;
    }
}
@media (min-width:601px) and (max-width:750px){
    .ChoseTenderInput{
        max-width: 400px !important;
    }
}
@media (min-width:751px) and (max-width:900px){
    .ChoseTenderInput{
        max-width: 550px !important;
    }
}
@media (min-width:1001px) and (max-width:1200px){
    .ChoseTenderInput{
        max-width: 380px;
    }
}
@media (min-width:1201px) and (max-width:1400px){
    .ChoseTenderInput{
        max-width: 250px;
    }
}
@media (min-width:1401px) and (max-width:1600px){
    .ChoseTenderInput{
        max-width: 270px;
    }
}
@media (min-width:1601px) and (max-width:1850px){
    .ChoseTenderInput{
        max-width: 340px;
    }
}
@media (min-width:1850px) and (max-width:2500px){
    .ChoseTenderInput{
        max-width: 400px;
    }
}