.rootContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.AddContractTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}


/* Mobile Resolution */
@media (min-width:200px) and (max-width:850px) {
    .AddContractTitle{
        font-size: 24px;
    }
    .AddContractBody{
        display: flex !important;
        flex-direction: column !important;
        gap: 25px !important;
    }

    .AddContractItem{
        /* width: 100% !important; */
        flex-direction: column !important;
        font-size: 13px !important;
        /* height: 35px !important; */
    }
    .AddContractItem label{
        min-width: 100px;
        max-width: 150px;
        justify-content: flex-start !important;
    }

    .AddDocsPANGSTWrapper{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        gap: 30px !important;
    }

    .AddInvoiceButtonWrapper{
        width: 100% !important;
        justify-content: center !important;
        padding-bottom: 20px !important;
    }
    
    .InvoiceBox{
        width: 100% !important;
    }
    .InvoiceBoxHeader{
        display: flex !important;
       flex-direction: column !important;
        justify-content: center !important;
 
        gap: 5px !important;
    }
    .InvoiceBoxAddPayment{
        display: flex !important;
        flex-direction: column !important;
        gap: 15px !important;
        width: auto !important;
        height: auto !important;
    }
    .AddContractDocs{
        flex-direction: column !important;
        align-items: center;
    }
    .AddDocsContractTitle{
        padding: 10px !important;
    }
    .DocContractUploadWrapper{
        align-items: center;
    }
    .DocUploadedContractWrapper{
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .InvoiceAddTableTH, .InvoiceAddTableTD{
        min-width: 50px !important;
        padding: 10px !important;
    }
    
.PopUpBox{
    margin-left: 20px !important;
    width: 90vw !important;
}
.PopUpBoxBody{
    display: flex;
    justify-content: center;
    align-items: center;
}
.PopUpInputWrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center !important;
}
}
@media (min-width:601px) and (max-width:850px){
    .PopUpBox{
        margin-left: 300px !important;
        width: 50% !important;
    }
    .DocUploadedContractWrapper{
        display: flex !important;
        flex-direction: column;
    }
    
}
@media (min-width:850px) and (max-width:1000px){
.AddDocsPANGSTWrapper{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 30px !important;
}
.DocUploadedContractWrapper{
   display: flex !important;
   flex-direction: column;
}
}
@media (min-width:950px) and (max-width:1270px){
    .DocUploadedContractWrapper{
        grid-template-columns: 1fr 1fr !important;
    }
    

}

@media (min-width:850px) and (max-width:1270px){
    .AddContractTitle{
        font-size: 26px;
    }
    .AddContractBody{
        gap: 15px !important;
    }
    .AddContractItem{
        /* width: 100% !important; */
        font-size: 13px !important;
        height: auto !important;
        display: flex !important;
        gap: 5px !important;
        flex-direction: column !important;
    }
    .AddContractItem label{
       justify-content: flex-start
    }
    .AddContractItem input,select{
        min-width: 120px !important;
        max-width: 220px !important;
        height: 35px !important;
    }
    .PopUpBox{
        margin-left: 360px !important;
        width: 50% !important;
    }
    .PopUpInputWrapper{
        gap: 30px;
    }
    .InvoiceBoxHeader{
       display: grid !important;
grid-template-columns: auto auto ;
        width: 100%;
        gap: 5px !important;
    }
    .AddInvoiceButtonWrapper{
        width: 100% !important;
        justify-content: center !important;
        padding-bottom: 20px !important;
    }
    .AddDocsPANGSTWrapper{
        grid-template-columns:auto auto !important;
        gap: 40px !important;
    }

    .InvoiceAddTableTH, .InvoiceAddTableTD{
        min-width: 50px !important;
        padding: 10px !important;
    }
    .InvoiceBoxAddPayment{
        display: flex !important;
        flex-direction: column;
        gap: 20px;
        height: auto !important;
    }
 
}


.AddContractBody{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding: 2px 20px;
}

.AddContractItem{
    display: flex;
    flex-direction: row;
    gap:20px;
}
.ContractorInputWrapper{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.AddContractItem label{
    flex:1;
    display: flex;
    justify-content: flex-end;
    color:var(--color-ctrlTextColor-grey);
    font-weight: bold;
}

.AddInvoiceButtonWrapper{
    display: flex;
    justify-content: flex-end;
    width: 93%;
    padding-bottom: 15px;
}

.AddInvoiceButton{
    background-color: var(--color-addButton-orange);
    border: #ffc000 solid 3px;
    padding: 5px;
    width: 140px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
}

.AddDocsPANGSTWrapper{
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    gap: 90px;
    padding: 10px 0px;
}
.AddContractDocsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.AddContractDocs{
    width: 80%;
    border: solid var(--color-border-blue) 1px !important;
    border-radius: 10px;
    padding: 10px 0px ;
    display: flex;
    flex-direction: row;
}
.AddDocsContractTitle{
    font-weight: bold;
    font-size: 18px;
    padding: 40px;
    color: var(--color-docSubmitHeader-blue)    ;
}
.DocUploadedContractWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}
.DocContractUploadWrapper{
    display: flex;
    justify-content: center;
   
    width: 100%;
    flex-direction: column;
    gap: 10px;
}
.AddPANGSTDocs{
    width: 350px;
    border: solid var(--color-border-blue) 1px !important;
    border-radius: 10px;
    padding-bottom: 10px ;
    display: flex;
    flex-direction: column;
}

.AddDocsHeader{
    color: white;
    font-weight: bold;
    display: flex;    
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--color-docSubmitHeader-blue);
}

.DocUploadWrapper{
    display: flex;   
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 15px;
}
.DocUploaded{
    border: solid var(--color-border-blue) 1px;
    padding: 5px 10px 5px 15px;
    min-width: 160px;
    max-width: 220px;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: white;
}
.DocInvoiceUploaded{
    border: solid var(--color-border-blue) 1px;
    padding: 5px 10px 5px 15px;
    min-width: 150px;
    max-width: 250px;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: white;
}
.DocInvoiceUploaded a , .DocUploaded a{
    max-width: 200px;
    white-space: nowrap; 
    text-overflow: ellipsis !important;
    overflow: hidden;
}

.DocUploadButton input{
    display: none;
}
.DocUploadButton label{
    cursor: pointer;
    background-color: var(--color-addButton-orange);
    color: white;
    font-weight: bold;
    text-align: center;
    width: 160px;
    padding: 5px 5px 5px 0px;
    border-radius: 5px;
}

.DocInvoiceUploadWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 200px;
    justify-content: center;
    align-items: center;
 
}

.DocInvoiceUploadButton input{
    display: none;
}
.DocInvoiceUploadButton label{
    cursor: pointer;
    background-color: var(--color-addButton-orange);
    color: white;
    font-weight: bold;
    text-align: center;
    min-width: 150px;
    max-width: 250px;
    padding: 5px 5px 5px 0px;
    border-radius: 5px;
   
}


.PopUpBox{
    margin-left: 400px;
    width: 60%;
}

.PopUpHeader{
    background-color: var( --color-usrPopupHeader-blue);
    color: white;
    font-weight: bold;
}
.PopUpTitle{
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 22px;
}
.PopUpInputWrapper{
    display: flex;
    justify-content: space-evenly;
}
.PopUpInput{
    width: 200px;
}
.PopUpSubmit{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 30px 0px;
}
.PopUpSubmitButton{
    background-color: var(--color-usrPopupSubmit-blue);
    color: white;
    border: solid 4px #a9ddb8;
    font-weight: bold;
    padding: 3px 30px 3px 30px;
    border-radius: 10px;
}
.InvoiceBoxWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 30px;
    gap: 20px;
}

.InvoiceBox{
    border: solid var(--color-cntrtFormInvoiceBorder) 1px;
    width: 100%;
    display: flex;
    padding: 10px 10px 40px 10px;
    flex-direction: column;
    justify-content: center;
   
    align-items: center;
    border-radius: 20px;
}
.InvoiceBoxHeaderTitle{
    color: #9d5300;
    font-weight: bold;
}
.InvoiceBoxHeaderContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.InvoiceBoxHeader{
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.InvoiceBoxAddPayment{
    background-color: var(--color-cntrtFormInvoiceBG);
    border-radius: 10px;
    border: solid var(--color-submitButton-blue) 1px;
    width: 90%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.UploadInvoiceButton{
    background-color: var(--color-addButton-orange);
    padding: 7px;
    width: 140px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    color: white;
}
.AddPaymentBttn{
        background-color: var(--color-addButton-orange);
        padding: 7px;
        width: 140px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        color: white;
}
.InvoiceBoxTableWrapper{
    overflow-x: scroll;
    width: 90%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;

}
.InvoiceBoxTable{
    padding: 20px;
    width: 100%;
    text-align: center;
}
.InvoiceAddTableHead{
    border-top: solid var(--color-cntrtFormInvoiceBorder) 3px;
    border-bottom: solid var(--color-cntrtFormInvoiceBorder) 3px;
}
.InvoiceAddTableHeadItems{
    color: var(--color-submitButton-blue);
}
.InvoiceAddTableTR{
    background-color: var(--color-cntrtFormInvoiceTable-blue);
}
.InvoiceAddTableTH, .InvoiceAddTableTD{
    padding: 15px;
}

.SubmitButtonWrapper{
    display: flex;
    padding: 30px;
    justify-content: center;
    align-items: center;
}