.CheckboxWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px ;
    width: 80%;
}
.checklabel{
    font-size: 12px;
    font-weight: bold;
}