.Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.surpriseButton{
    display: none;
    align-items: center;
    justify-content: center;
}
.TextFieldContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:15px;
}

.ButtonContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TextField{
    min-width: 250px;
}



/* @media screen and (min-width:13000px) {
    .TextField{
        min-width: 250px;
    
      }

} */


@media (min-width:1200px) and (max-width:13000px) {
    .TextField{
        min-width: 220px !important;
    
      }

} 

@media screen and (max-width:1200px) {
  .TextField{
    min-width: 250px !important;

  }
  .ButtonContainer{
    display: none;
  }
  .surpriseButton{
    display: block;
  }
  .TextFieldContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

}

@media screen and (max-width:992px) {
    .surpriseButton{
        display: none;
    }
    .TextFieldContainer{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 10px;
    }
    .Container{
        padding: 20px 0px !important;
        display: flex;
        flex-direction: column;
        gap:20px !important;
    }

    .ButtonContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
     

}


@media screen and (max-width:400px) {
    .surpriseButton{
        display: none;
    }
    .TextFieldContainer{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 10px;
    }
}


@media screen and (max-width:600px) {
    .surpriseButton{
        display: none;
    }
    .TextFieldContainer{
        display: grid;
        grid-template-columns: 1fr ;
        column-gap: 10px;
    }
}

@media screen and (max-width:700px) {
    .surpriseButton{
        display: none;
    }
    .TextFieldContainer{
        display: grid;
        grid-template-columns: 1fr ;
        column-gap: 10px;
    }
}

.error{
    
        color: red;
        font-size: 12px;
    }


    .TextWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }