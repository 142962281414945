.DocumentsFoldersWrapper{
padding: 10px;
}

/* @media (min-width:200px) and (max-width:600px) {
    .DocumentsFoldersWrapper{
        grid-template-columns: 1fr !important;
        padding: 5px;
        gap: 15px;
    }
}
@media (min-width:600px) and (max-width:800px) {
    .DocumentsFoldersWrapper{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width:800px) and (max-width:992px) {
    .DocumentsFoldersWrapper{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
} */

.DocumentSection{
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    
}

.Card{
    padding: 10px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    
}
.DeleteContainer{
    margin-top: -5px;
    padding-right: 5px;
    /* margin-right: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
.DocumentsFolderItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
}
.folderImage{
    width: 80px;
    min-height: 80px;
    cursor: pointer;
    object-fit: contain;
}
.DocumentsFolderTitle{
    text-align: center;
    color: var(--color-documentFolderLabel-grey);
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
    padding: 0px;
}   

.BackButton{
    background-color: var( --color-docSubFolderBack-blue);
    color: white;
    padding: 10px 40px;
    border: none;
    border-radius: 5px;
}
.BackButtonWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Input{
    width: 100%;
    font-size: 13px;
}

.TextContainer{
    min-height: 40px;
    display: flex;
    align-items: center;
}

.InputContainer{
    display: flex;
    align-items: center;
    min-height: 40px;
    width:100%;
}