.CtrlFields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Mobile Resolution */
/* @media (min-width:200px) and (max-width:750px) */
@media (min-width:200px) and (max-width:750px) {
    .CtrlFields {
        padding: 20px !important;
    }

    .CtrlFldsBodyContainer {
        width: 100% !important;
    }

    .CtrlFldTitle {
        font-size: 25px !important;
    }

    .CtrlFldsBodyTitle {
        font-size: 15px !important;
    }

    .CtrlFldsBodyAddInput {
        max-width: 140px !important;
    }

    .CtrlFldsBodyAdd {
        gap: 10px !important;
    }

    .SeperatorLine {
        max-width: 80% !important;
    }

    .CtrlFldsBodyItems {
        grid-template-columns: auto !important;
        justify-content: center !important;
        align-items: center !important; 
        width: 100%;
    }


    .listCard {
       min-width: 220px !important;
    }

    .CtrlFldsItemInput {
        text-align: center;
    }

    .CtrlFldsBodyContent {
        margin: auto !important;
        padding: 25px !important;
    }

    .CtrlFldsSubmitEdit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px !important;
        font-size: 15px !important;
    }
}

@media (min-width:751px) and (max-width:950px) {
    .CtrlFldsBodyItems {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (min-width:950px) and (max-width:1180px) {
    .CtrlFldsBodyItems {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

.CtrlFldTitle {
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 13px;
    font-size: 20px;
    background-color: var(--color-usrMgt-titleblue);
}

.CtrlFldIndexBody{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 20px;
}

.CtrlFldsBodyContainer {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border: solid var(--color-border-blue) 1px;
    width: 100%;
    border-radius: 35px;
}

.CtrlFldsBodyContent {
    padding: 25px 35px 30px 40px;
}


.CtrlFldsBodyTitle {
    display: flex;
}

.CtrlFldsBodyAdd {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    padding: 10px 0px;
}

.CtrlFldsBodyAddInput {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    color: var(--color-ctrlTextColor-grey);
}

.SubmitButton {
    width: 130px;
    height: 37px;
}

.CtrlFldsInputWrapper {
    display: flex;
    flex-direction: column;
}

.emptyMessage {
    font-size: 14px;
    text-align: center;
    color: red;
}

.Seperator {
    display: flex;
    justify-content: space-around;

}

.SeperatorLine {
    border-top: solid var(--color-border-blue) 4px;
    width: 92%;
    margin: 15px 0px 0px 0px;

}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: black solid 1px;
    border-radius: 100%;
    cursor: pointer;
}

.CtrlFldsBodyItems {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 5px;
    margin: 20px 0px 25px 10px;
    gap: 25px;
    max-height: 300px;
    overflow: auto;
}

.CtrlFldsItem {
    color: var(--color-ctrlTextColor-grey);
    background-color: var(--color-ctrlListItems-pale);
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    width: 180px;
}

.CtrlFldsItemInput {
    color: var(--color-ctrlTextColor-grey);
    border: solid #bccadd 3px;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    width: 180px;
}

.CtrlFldsSubmitEdit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-size: 18px;
}

.CtrlFldsSubmitButton {
    background-color: var(--color-usrPopupSubmit-blue);
    border: solid var(--color-ctrlFieldSubmitBorder-blue) 4px;
    color: white;
    padding: 5px 40px 5px 40px;
    border-radius: 7px;
    font-weight: bold;

}

.CtrlFldsCancelEdit {
    background-color: var(--color-ctrlFieldCancel-lightBlue);
    color: white;
    padding: 5px 40px 5px 40px;
    border-radius: 7px;
    border: none;
    font-weight: bold;
}

.listCard {
    display: flex;
    justify-content: center;
    padding: 8px 5px;
    align-items: center;
    background-color: var(--color-ctrlListItems-pale) !important;
    font-size: 16px !important;
}