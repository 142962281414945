.rootContainer {
    display: flex;
    flex-direction: column;
}
.ListWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 50px;
}

.ListTitle {
    width: 100%;
    display: flex;
    max-width: 250px;
    min-width: 250px;
    background-color: var(--color-document-darkBlue);
    justify-content: center;
    align-items: center;
}
.ListTitleAlt{
    width: 100%;
    display: flex;
    max-width: 250px;
    min-width: 250px;   
    justify-content: center;
    align-items: center;
}
.ListTitle label{
   
    color: white;
    font-weight: bold;
}
.ListTitleAlt label{
    
    color:  var(--color-document-darkBlue);
    font-weight: bold;
}

.ListBody {
    width: 100%;
    display: grid;
    background-color: var(--color-document-lightBlue);
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 15px;
}
.ListBodyAlt{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 15px;
}
.ListDocument {
    display: flex;
    justify-content: center;
}
.DocLabelWrapper{
    display: flex;
    justify-content: space-between;
}
.xMark{
    font-size: 15px;
    margin: 0;
    cursor: pointer;
}
.DocLabel{
    flex:10;
    font-size: 13px;
    font-weight: bold;
   text-decoration: none;
   color: var(--color-documentLabel-grey);
   max-width: 230px;
   white-space: nowrap; 
   text-overflow: ellipsis !important;
   overflow: hidden;
   text-align: center;
}
.doctypeLabel{
    margin: -5px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: var(--color-documentLabel-grey);
}
.NoDocsMessage{
    display: flex;
    font-weight: bold;
    justify-content: center;
    color: var(--color-document-darkBlue);
    font-size: 15px;
}
    @media (min-width:200px) and (max-width:600px) {
        .ListWrapper{
        flex-direction: column;
        }
        .ListBody,.ListBodyAlt{
            grid-template-columns: 1fr;
        }
        .ListTitle,.ListTitleAlt{
            max-width: none;
            padding: 10px;
        }
    }
    @media (min-width:601px) and (max-width:767px) {
        .ListWrapper{
           flex-direction: column;
        }
        .ListBody,.ListBodyAlt{
            grid-template-columns: 1fr;
        }
        .ListTitle,.ListTitleAlt{
            max-width: none;
            padding: 10px;
        }
    }
    @media (min-width:768px) and (max-width:1024px) {
        .ListWrapper{
           flex-direction: column;
        }
        .ListBody,.ListBodyAlt{
            grid-template-columns: 1fr 1fr;
        }
        .ListTitle,.ListTitleAlt{
            max-width: none;
            padding: 10px;
        }
    }
    @media (min-width:1024px) and (max-width:1300px) {
        .ListBody,.ListBodyAlt{
            grid-template-columns: 1fr 1fr;
        }
        .ListTitle,.ListTitleAlt{
            padding: 10px;
        }
    }